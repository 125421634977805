import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import { StaticImage } from "gatsby-plugin-image"
import OptimizationAiCasestudyList from "src/component/list/optimization-aiCasestudyList"

export default function OptimizationAi() {
    return (
        <>
         <Casestudies>
          <div className="optimization-ai inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_optimization-ai.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_optimization-ai-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>最適化AI</p>
                                  <span>OPTIMIZATION AI</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  AIが物流・スケジューリング・在庫管理などの業務をリアルタイムで最適化。
                                  <br />
                                    計画やリソース配分を自動算出し、業務効率と生産性を最大化。
                                    <br className="c-pc" />
                                    企業のコスト削減と競争力向上を支援します。
                               </p>
                         </div>
        
                    </div>
                </section>

                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>リソース配分の<br />自動最適化で、業務効率・コスト効率を劇的に改善</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>AIで配送ルート最適化</h3>
                                 <p>
                                    交通・天候・荷量をリアルタイム分析し、配送コストと時間を最小化して物流効率を向上。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI在庫補充の自動最適化</h3>
                                 <p>
                                    販売・市場動向をリアルタイムで分析し、在庫不足や過剰を防ぎコスト削減を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>需要予測で生産最適化</h3>
                                 <p>
                                    需要予測をもとに資材・在庫状況を考慮し、生産効率を最大化するスケジュールを自動作成。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで省エネ運用最適化</h3>
                                 <p>
                                    設備状況・エネルギー価格をリアルタイム分析し、省エネと運用コスト削減を同時に実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで人員配置を最適化</h3>
                                 <p>
                                    スキルや稼働状況をリアルタイム分析し、生産性と人件費効率を高める人員配置を自動設計。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで動的価格最適化</h3>
                                 <p>
                                    競合価格や市場状況をリアルタイム分析し、収益を最大化する価格を自動で算出・設定。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <OptimizationAiCasestudyList />
                    </div>
                </section>
            </div>
          </Casestudies>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="OPTIMIZATION AI"
            description=""
            pathname=""
        />
    )
}